.layout-details {
    width: 95%;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
    margin-top: 55px;
    margin-bottom: 15px;
    font-family: Arial, sans-serif;
}

.layout-name {
    background-color: #f0f0f0;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block;
    margin-bottom: 20px;
}

.layout-name h1 {
    font-size: 2rem;
    margin: 0;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.layout-description {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
    text-align: left;
    margin-bottom:20px;
}

.description-content {
    flex: 1;
    padding:15px;
    text-align: justify;
}

.description-content h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

.description-content p {
    font-size: 1rem;
    color: #555;
}

.layout-sidebar {
    flex: 0 0 300px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-details {
    margin-top: 40px;
}

.section-details h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

.details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    text-align: left;
}

.plot-details {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.plot-details h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #555;
}

.plot-details p {
    margin: 5px 0;
    font-size: 1rem;
    color: #777;
}
.posted-by-section {
    margin: 30px 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .posted-by-title {
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .posted-by-content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #e0e0e0;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-info {
    flex: 1;
  }
  
  .name-and-role {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .name {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .role {
    background-color: #4CAF50;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    text-transform: capitalize;
  }
  
  .contact-buttons {
    display: flex;
    gap: 10px;
  }
  
  .whatsapp-button,
  .show-number-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .whatsapp-button {
    background-color: #25D366;
    color: white;
  }
  
  .show-number-button {
    background-color: #007bff;
    color: white;
  }
  
  .whatsapp-button:hover,
  .show-number-button:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .posted-by-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .profile-info {
      width: 100%;
    }
  
    .name-and-role,
    .contact-buttons {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .contact-buttons {
      flex-direction: column;
    }
  
    .whatsapp-button,
    .show-number-button {
      width: 100%;
    }
  }
.grid-image {
    width: calc(33.33% - 10px);
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.grid-image:hover {
    transform: scale(1.05);
}

.carousel-image-layout {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.view-map-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view-map-button:hover {
    background-color: #555;
}

.sidebar-section {
    margin-bottom: 20px;
}

.sidebar-section h2 {
    font-size: 1.6rem;
    margin-bottom: 10px;
    color: #333;
}

.sidebar-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-section ul li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
}

.sidebar-section p {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
}

.whatsapp-share {
    position: fixed;
    bottom: 85px; /* Adjust this value to change vertical position */
    right: 20px; /* Adjust this value to change horizontal position */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    cursor: pointer;
    z-index: 1000; /* Ensure the container stays above other content */
}

.whatsapp-icon {
    width: 60px; /* Set width of the icon */
    height: 60px; /* Set height of the icon */
    cursor: pointer;
}

.whatsapp-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Maintain aspect ratio */
    border-radius: 50%; /* Make it circular */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.whatsapp-icon img:hover {
    transform: scale(1.1); /* Add a slight zoom effect on hover */
}

.share-text {
    margin-top: 5px; /* Space between the icon and text */
    font-size: 14px; /* Adjust font size as needed */
    color: #25D366; /* WhatsApp green color */
    cursor: pointer;
    text-align: center; /* Center text under the icon */
}

.breadcrumb {
    display: flex;
    align-items: center;
    font-size: 16px; /* Increased font size for better readability */
    margin-bottom: 10px;
    color: #6c757d;
    font-weight: 500;
    padding: 10px 0; /* Added padding for better spacing */
}

.breadcrumb a {
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease;
    font-weight: 600; /* Bolder text for links */
    color: #495057;
}

.breadcrumb a:hover {
    color: #abaeb1;
}

.breadcrumb-separator {
    margin: 0 10px; /* Increased margin for better spacing */
    color: #adb5bd; /* Slightly lighter color for the separator */
}

.breadcrumb span {
    color: #343a40; /* Darker color for the current page for better emphasis */
    font-weight: 600;
}
.details-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Ensures 5 boxes per row */
    gap: 20px;
    margin-bottom: 40px;
}

.detail-box {
    background-color: #ffffff; /* A brighter background for a cleaner look */
    padding: 25px;
    border-radius: 15px; /* Increased border-radius for a smoother look */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Slightly larger shadow for more depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.detail-box:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Enhance the shadow on hover */
}

.detail-title {
    font-size: 2.2rem; /* Slightly larger title */
    margin: 0;
    color: #222; /* Darker color for better contrast */
}

.detail-subtitle {
    font-size: 1.1rem; /* Slightly larger subtitle */
    color: #666; /* Softer gray for the subtitle */
}



@media (max-width: 768px) {
    .grid-image {
        width: 100%;
        height: auto;
    }

    .details-grid {
        grid-template-columns: 1fr;
    }

    .layout-content {
        flex-direction: column;
    }

    .layout-sidebar {
        min-width: 100%;
    }

    .layout-description {
        flex-direction: column;
    }
    
}

.table-container {
    margin-top: 40px;
    padding: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
}

.table th,
.table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.table th {
    background-color: #333;
    color: #fff;
}

.table td {
    background-color: #f9f9f9;
    color: #555;
}

.table tr:nth-child(even) {
    background-color: #ededed;
}

.table .price-btn {
    background-color: #837983;
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.table .price-btn:hover {
    background-color: #6f606f;
}

.table .price-btn i {
    margin-left: 5px;
}

.new-section {
    margin-top: 50px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.new-section-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.new-section-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.new-section-details {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}

.new-detail-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.new-detail-item img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.new-detail-item p {
    margin: 0;
    font-size: 1rem;
    color: #555;
}

.new-section-image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.new-section-image img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    object-fit: cover;
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-top: 45%; /* 16:9 Aspect Ratio */
    margin-bottom: 40px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
}

/* Media query for larger screens (desktop) */
@media (min-width: 1024px) {
    .iframe-container {
        padding-top: 46%; /* Adjust height for desktop screens if needed */
    }
}

/* Media query for smaller screens (mobile/tablet) */
@media (max-width: 1023px) {
    .iframe-container {
        padding-top: 130%; /* Adjust for mobile aspect ratio */
    }
}
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.map-button {
    position: absolute;
    bottom: 10px; /* Move button to the bottom */
    left: 10px; /* Keep button on the left */
    z-index: 10;
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.map-button:hover {
    background-color: #555;
}


@media (max-width: 768px) {
    .new-detail-item {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .new-detail-item {
        flex: 1 1 100%;
    }
}

@media (max-width: 768px) {
    .new-section-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .new-section-image {
        margin-top: 20px;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .table th, .table td {
        padding: 8px;
    }

    .table .price-btn {
        padding: 6px 10px;
    }
}

/* Ensure the container has padding on the sides */
.container {
    padding-left: 15px;
    padding-right: 15px;
}

/* General styles for the location-box */
.location-box {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    height: 100%;
}

/* Font size and weight styles */
.fs-4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.fw-bold {
    font-weight: 700;
}

/* List styles */
.list-1 {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.list-1 li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 8px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .location-box {
        padding: 10px;
    }

    .fs-4 {
        font-size: 1.25rem;
    }

    .list-1 li {
        font-size: 0.875rem;
    }
}

@media (max-width: 576px) {
    .container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .location-box {
        padding: 8px;
    }

    .fs-4 {
        font-size: 1.125rem;
    }

    .list-1 li {
        font-size: 0.75rem;
    }
}

.report-section {
    margin: 30px auto; /* This will center the section like activity-section */
    width: 90%; /* Set a width like activity-section */
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
}
.report-button {
    flex: 1; /* Make buttons flexible, equally spaced */
    padding: 10px 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 150px; /* Ensure buttons have a minimum width */
    text-align: center;
  }
  
  .report-button:hover {
    background-color: #e2e2e2;
  }
  .report-options {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping, keep buttons in a single row */
    justify-content: space-between; /* Space buttons evenly */
    gap: 10px; /* Space between buttons */
    margin-top: 20px; /* Space above buttons */
}
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .report-options {
      flex-direction: column; /* Stack buttons vertically on small screens */
      gap: 15px; /* Increase the space between stacked buttons */
    }
  
    .report-button {
      width: 100%; /* Make buttons take full width on small screens */
    }
  }
.report-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.warning-icon {
    color: #ffcc00;
    font-size: 24px;
    margin-right: 15px;
}

.report-box h3 {
    font-size: 18px;
    color: #333;
    margin: 0;
}

.report-options {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-top: 10px;
}

.report-options label {
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
    display: flex;
    align-items: center;
}

.report-options input {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .report-box {
        flex-direction: column;
        align-items: flex-start;
    }
    .report-options {
        margin-left: 0;
    }
}

.activity-section {
    width: 90%;
    margin: 20px auto;
    text-align: center;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.activity-heading {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.activity-stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}

.activity-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    color: #555;
}

.activity-item img {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .activity-stats {
        flex-direction: column;
        gap: 10px;
    }
}
/* Disclaimer Section Styling */
.disclaimer-section {
    width: 90%;
    margin: 30px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.disclaimer-heading {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #333;
    font-weight: bold;
    text-align: center;
}

.disclaimer-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .disclaimer-section {
        padding: 15px;
    }
    
    .disclaimer-heading {
        font-size: 1.6rem;
    }
    
    .disclaimer-text {
        font-size: 0.9rem;
    }
}

