.profile-page {
    top:35px;
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Glassy effect */
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    color: #333;
    max-width: 1200px;
}

.profile-header {
    margin-top:60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #730893, #994949);
    border-radius: 12px;
    padding: 25px 20px;
    margin-bottom: 30px;
    color: white;
}

.profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 4px solid #fff;
    object-fit: cover; /* Ensures the image fits correctly */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-header h2 {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    color:white;
}

.profile-section {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease;
}

.section-header {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.section-header h3 {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.section-icon {
    font-size: 24px;
    margin-right: 15px;
    color: #343a40;
}

.section-arrow {
    font-size: 24px;
    color: #343a40;
    transition: transform 0.3s ease;
}

.profile-section:hover .section-arrow {
    transform: translateX(5px);
}

.scrollable-cards {
    display: flex;
    overflow-x: auto;
    margin-top: 20px;
    padding: 10px 0;
    scroll-behavior: smooth;
}



@media (max-width: 768px) {
    .profile-page {
        width: 100%;
        padding: 15px;
    }

    .profile-header {
        padding: 20px 10px;
    }

    .profile-header h2 {
        font-size: 22px;
        color:white;
    }

    .profile-pic {
        width: 100px;
        height: 100px;
    }

    .section-icon, .section-arrow {
        font-size: 20px;
    }

}
