/* ContactModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-family: 'Arial', sans-serif;
}

.modal {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 350px;
    max-width: 100%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1rem;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

.heading {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-weight: 400;
}

.form-group {
    margin-bottom: 20px;
    position: relative;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #a51194;
    outline: none;
}

.submit-button {
    width: 100%;
    padding: 12px;
    background: #680850;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.3s ease;
}

.submit-button:hover {
    background: #000000;
}
