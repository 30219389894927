.homepage {
  width: 100%;
  margin: 0 auto;
  padding: 0px;
}
.homepage {
  position: relative;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.video-overlay h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.video-overlay p {
  font-size: 1.5rem;
}

.search-bar-container {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-bar {
  display: flex;
  max-width: 800px;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
  overflow: hidden;
}

.search-input,
.search-select,
.search-button {
  border: none;
  padding: 15px;
  font-size: 1rem;
}

.search-input {
  flex-grow: 1;
}

.search-select {
  width: 200px;
}

.search-button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #0056b3;
}

/* Adjust other sections to start after the video */
.card-list-heading,
.why-use-section,
.features-section {
  margin-top: 50px;
}

/* ... (keep your existing styles for other sections) ... */

.carousel .slide img {
  width: 100%;
}

.carousel-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.carousel-title {
  margin: 0;
  font-size: 34px;
  font-weight: bold;
}

.carousel-subtitle {
  font-size: 24px;
  font-weight: bold;
}

.card-list-container {
  display: flex;
  align-items: center;
  margin-top: 25px;
  overflow: hidden;
}

.card-list {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 10px;
}

.card {
  flex: 0 0 calc((100% - 44px) / 3); /* Adjust the width for three cards per row */
  margin: 0 10px; /* Adjust margin for spacing */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-content {
  padding: 15px;
}

.card-title {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.card-description {
  font-size: 14px;
  color: #555;
}

.arrow-icon {
  cursor: pointer;
  font-size: 24px;
  color: #333;
  margin: 0 10px;
}

.card-list-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: bold;
}

.contact-heading {
  text-align: center;
  margin-top: 2rem;
  font-size: 2rem;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 2rem 0;
  gap: 2rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 0.5rem;
}

.form-group input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 0.75rem;
 background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-button:hover {
  background-color: #333;
}

.contact-info {
  max-width: 300px;
  width: 90%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-info h3 {
  margin-bottom: 1rem;
}

.contact-info p {
  margin: 0.5rem 0;
}

.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.footer-social {
  margin-bottom: 10px;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}


/* Base Styles for Badge Section */
.approval {
  padding: 20px;
  background-color: #f9f9f9;
}

.app-box {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
  height: 100%; /* Ensure box stretches */
}

.app-box img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto;
}


.container {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 90px; /* Space between items */
}

.col-6, .col-md-3 {
  flex: 0 0 auto; /* Adjust to content size */
  max-width: 100%; /* Full width within parent container */
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px; /* Ensure padding is consistent */
}

.row {
  box-sizing: border-box; /* Ensure padding doesn’t affect width */
}


.mb-2 {
  margin-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .app-box img {
    max-width: 120px; /* Adjust for mobile */
  }

  .col-6, .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row {
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .carousel-image {
    height: 300px;
  }

  .card {
    flex: 2 6 100%;
  }

  .card-list {
    flex-wrap: wrap;
  }

  .card-list-container {
    flex-direction: column;
    align-items: center;
  }

  .arrow-icon {
    cursor: pointer;
    font-size: 24px;
    color: #333;
    margin: 0 10px;
  }

  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-info {
    margin-top: 1rem;
    max-width: 100%;
    margin-bottom: 60px;
  }
  .footer{
    display: none;
  }
  
}

/* Basic reset for margin and padding */
body, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

/* Container padding */
.container {
  padding: 20px;
}

/* Ensure the images are responsive */
.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}


/* Styling for the details text */
.details-wrapper {
  text-align: center; /* Center align text and images */
}

.details-text {
  margin-top: 15px;
  font-size: 1.125rem; /* Responsive font size */
  color: #333;
}
.main-headline {
  text-align: center;
  font-size: 2rem;
  margin: 20px 0;
  color: #333;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .details-text {
      font-size: 1rem;
  }
}
/* SearchBar.css */

.search-bar {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 20px auto;
  padding: 5px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  border: 2px solid #000;
}

.search-input {
  padding: 10px 15px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  flex: 1;
  min-width: 150px;
  transition: all 0.3s ease;
  border-right: 1px solid #ccc;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  padding-left: 40px;
  background-image: url('https://cdn-icons-png.flaticon.com/512/3474/3474041.png');
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 20px;
}

.search-select {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  flex: 1;
  min-width: 150px;
  background-color: transparent;
  background-image: url('https://cdn-icons-png.flaticon.com/512/4631/4631616.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 40px;
}

.search-button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: auto;
}

.search-input:focus, .search-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-input::placeholder, .search-select {
  color: #333;
  font-weight: bold;
}

.search-select option {
  color: #333;
  font-weight: normal;
}

@media (max-width: 768px) {

  .main-headline {
    font-size: 1.5rem;
  }
  .search-bar {
    flex-direction: column;
    padding: 10px;
  }
  .search-input, .search-select, .search-button {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  .search-input {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
  .search-button {
    padding: 10px 20px;
    width: 100%;
  }
}


/* Add this new section for "Why use LandLayout?" */

.why-use-section {
  padding: 40px 20px;
  background-color: #f7f7f7;
  text-align: center;
}

.why-use-heading {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.why-use-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.why-use-box {
  flex: 1 1 200px;
  max-width: 250px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.why-use-box img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.why-use-text {
  font-size: 1rem;
  color: #555;
}
.features-section {
  
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.features-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  gap: 20px;
}

.feature-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.icon-container {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
}

.feature-item h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

.feature-item p {
  font-size: 1rem;
  color: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .features-heading {
    font-size: 1.5rem;
  }

  .feature-item {
    padding: 15px;
  }

  .icon-container {
    font-size: 1.5rem;
  }

  .feature-item h3 {
    font-size: 1.125rem;
  }
}

/* Base styles for the icon container */
.icon-container {
  width: 40px; /* Default width */
  height: 40px; /* Default height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* Center the container if needed */
}

.icon-image {
  width: 100%;
  height: auto;
}

/* Responsive styles */
@media (max-width: 480px) {
  .icon-container {
    width: 50px; /* Smaller width for very small screens */
    height: 50px; /* Smaller height for very small screens */
  }
}

.testimonial-section {
  padding: 50px;
  background: #f7f7f7;
  text-align: center;
}

.testimonial {
  position: relative;
  padding: 20px;
  font-style: italic;
}

.quote-icon {
  position: absolute;
  top: -10px; /* Adjusted top position to move the icon up */
  left: 50%;
  transform: translate(-50%, -50%); /* Center the icon horizontally */
  width: 20px; /* Adjusted width for a smaller icon */
  height: auto; /* Maintain aspect ratio */
  opacity: 0.5; /* Slightly increased opacity for better visibility */
}
.testimonial p {
  font-size: 18px;
  color: #333;
  margin: 20px 0;
}

.testimonial h4 {
  margin-top: 20px;
  font-size: 16px;
  color: #555;
}


@media (max-width: 768px) {
  .testimonial p {
    font-size: 1rem;
  }

  .testimonial h4 {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
}
